import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 18,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 18,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.common.shop'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.shop') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.product.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.ranking'),
      placeholder: i18n.t('placeholder.showRanking'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) },
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.ranking') }), trigger: 'blur' }
        ]
      }
    }, {
      span: 18,
      prop: 'intro',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionCn'),
      Object: {}
    }, {
      span: 18,
      prop: 'intro_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionEn'),
      Object: {}
    }, {
      span: 18,
      prop: 'desc',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 18,
      prop: 'desc_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 10
      }
    }, {
      span: 8,
      prop: 'rest_city_id',
      name: 'select',
      options: [],
      label: i18n.t('form.rest.city'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.city') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'area',
      name: 'input',
      label: i18n.t('form.rest.area'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.rest.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'url',
      name: 'input',
      label: i18n.t('form.rest.website'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'phone',
      name: 'input',
      label: i18n.t('form.rest.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.rest.email'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('menu.hotelFacility'),
    name: 1,
    createDateItem: [
      {
        span: 12,
        prop: 'rest_facility_ids',
        name: 'group-checkbox',
        value: [],
        options: [],
        Object: {
          allCheck: false,
          key: 'id',
          value: 'id',
          label: i18n.locale == 'en' ? 'name_en' : 'name',
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('menu.hotelFacility') }) }
          ]
        }
      }
    ]
  }, {
    title: i18n.t('menu.hotelLabel'),
    name: 2,
    createDateItem: [
      {
        span: 12,
        prop: 'rest_tag_ids',
        name: 'group-checkbox',
        value: [],
        options: [],
        Object: {
          allCheck: false,
          key: 'id',
          value: 'id',
          label: i18n.locale == 'en' ? 'name_en' : 'name',
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('menu.hotelLabel') }) }
          ]
        }
      }
    ]
  }, {
    title: i18n.t('form.rest.restPolicy'),
    name: 3,
    createDateItem: [{
      span: 18,
      prop: 'rest_policies_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.rest.restPolicy'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 8,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'rank',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.ranking'),
        placeholder: i18n.t('placeholder.showRanking'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) },
            { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.ranking') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 24,
        prop: 'desc',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.common.descriptionCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'desc_en',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.common.descriptionEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }]
  }]
}
