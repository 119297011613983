<template>
  <alter-form
    labelWidth="150px"
    :watchImageVisible="true"
    :collapseLength="collapseLength"
    :modifyData="modifyData"
    @formSubmit="formSubmit"
    :formData="formData"
  />
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
import {
  createRest, 
  getRestLabelList,
  getRestFacilityList,
  getRestInfoPreview,
  updateRestInfo
} from '@/services/rests';
import { getRestCityList } from '@/services/setting'

import constant from '@/utils/constant.js'
import * as shopsApi from '@/services/shops.js'
import jsonData from './jsonData'
import utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import _ from 'lodash'
export default {
  components: {
    alterForm
  },
  data () {
    return {
      constant,
      formData: _.cloneDeep(jsonData.formData),
      modifyData: {},
      collapseLength: [],
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    async initData () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const params = {page: 1, per_page: 1000}
      const res = await shopsApi.getShopsSimpleList()
      this.formData[0].createDateItem[1].options = res.data.shops
      const facilityRes = await getRestFacilityList(params)
      const { arr } = utils.formatTableData(facilityRes.data.objects)
      this.formData[1].createDateItem[0].options = arr
      const labelRes = await getRestLabelList(params)
      const arr1 = utils.formatTableData(labelRes.data.objects).arr
      this.formData[2].createDateItem[0].options = arr1
      const cityRes = await getRestCityList(params)
      const arr2 = utils.formatTableData(cityRes.data.objects).arr
      this.formData[0].createDateItem[10].options = arr2
      if (this.id) {
        const restRes = await getRestInfoPreview(this.id)
        if (restRes.status == 200 && restRes.data) {
          let restInfo = JSON.parse(JSON.stringify(restRes.data.rest))
          if (restInfo.images && restInfo.images.length > 0) {
            this.imgList = restInfo.images
            restInfo.images_attributes = restInfo.images
            delete restInfo.images
          }
          if (restInfo.rest_policies && restInfo.rest_policies.length > 0) {
            restInfo.rest_policies_attributes = restInfo.rest_policies
            delete restInfo.rest_policies
          }
          restInfo = this.initCheckGroupData(restInfo, 'rest_facilities', 'rest_facility_ids')
          restInfo = this.initCheckGroupData(restInfo, 'rest_tags', 'rest_tag_ids')
          this.modifyData = { ...restInfo }
        }
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      let api = () => createRest(data)
      if (this.id) {
        api = () => updateRestInfo(this.id, data)
      }
      api().then((res) => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'restsManagerRestList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },

    initCheckGroupData (obj, key, key1) {
      if (obj[key].length > 0) {
        const facilities = _.cloneDeep(obj[key])
        delete obj[key]
        obj[key1] = []
        facilities.forEach((d) => {
          obj[key1].push(d.id)
        })
      }
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
</style>